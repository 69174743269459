import React, { useState, useEffect, useRef } from "react";
import { NavLink } from "react-router-dom";
import { useLocation } from "react-router-dom";
import Notify from "./Notify";
import logo from "../Assets/Images/developedbybarnlogo.png";

const Navbar = () => {
  const [showNavbar, setShowNavbar] = useState(false);
  const [isDarkMode, setIsDarkMode] = useState(() => {
    return localStorage.getItem("darkMode") === "true";
  });

  const [notification, setNotification] = useState(null);

  const handleShowNavbar = () => {
    if (window.innerWidth > 768) {
      return;
    } else {
      setShowNavbar(!showNavbar);
      if (!showNavbar) {
        disableBodyScroll();
      } else {
        enableBodyScroll();
      }
    }
  };

  const showNotification = (message, type) => {
    setNotification({ message, type });
  };

  const handleThemeChange = () => {
    const newDarkMode = !isDarkMode;
    setIsDarkMode(newDarkMode);
    localStorage.setItem("darkMode", newDarkMode);

    showNotification(
      newDarkMode ? "Switched to dark mode" : "Switched to light mode",
      "success"
    );
  };

  const disableBodyScroll = () => {
    document.body.style.overflow = "hidden";
  };

  const enableBodyScroll = () => {
    document.body.style.overflow = "visible";
  };

  useEffect(() => {
    const root = document.documentElement;
    if (isDarkMode) {
      root.classList.add("dark-theme-variables");
      enableBodyScroll();
    } else {
      root.classList.remove("dark-theme-variables");
      enableBodyScroll();
    }
  }, [isDarkMode]);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 768) {
        document.body.style.overflow = "visible";
      }
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const { pathname } = useLocation();

  useEffect(() => {
    const delay = setTimeout(() => {
      const elements = document.querySelectorAll(
        ".slideFromTop, .slideFromRight, .slideFromBottom"
      );

      elements.forEach((element) => {
        element.classList.add("animate");
      });
    }, 199);

    return () => clearTimeout(delay);
  }, [pathname]);

  const hiddenElementsRef = useRef([]);
  const observerRef = useRef();

  useEffect(() => {
    observerRef.current = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add("show");
            hiddenElementsRef.current = hiddenElementsRef.current.filter(
              (ref) => ref !== entry.target
            );
          }
        });
      },
      { threshold: 0.2 }
    );

    hiddenElementsRef.current.forEach((ref) => {
      observerRef.current.observe(ref);
    });

    return () => {
      observerRef.current.disconnect();
    };
  }, []);

  useEffect(() => {
    const hiddenElements = document.querySelectorAll(".hidden");
    hiddenElementsRef.current = Array.from(hiddenElements);
    hiddenElements.forEach((fade) => observerRef.current.observe(fade));

    return () => {
      hiddenElementsRef.current.forEach((ref) =>
        observerRef.current.unobserve(ref)
      );
    };
  }, [pathname]);

  return (
    <>
      <nav className={`navbar ${isDarkMode ? "dark" : "light"}`}>
        <div className="nav-container">
          <div className="logo">
            <a href="https://developedbybarn.com">
              <div className="brand">
                <img src={logo} alt="Barnabas Mwenda Logo" />
              </div>
            </a>
          </div>
          <div className="menu-icon" onClick={handleShowNavbar}>
            <div className={`menu-btn_burger  ${showNavbar && "active"}`}></div>
            <div
              className={`menu-btn_burger2  ${showNavbar && "active"}`}
            ></div>
            <div
              className={`menu-btn_burger3  ${showNavbar && "active"}`}
            ></div>
          </div>
          <div className={`nav-elements  ${showNavbar && "active"}`}>
            <ul>
              <li>
                <NavLink onClick={handleShowNavbar} to="/">
                  Home
                </NavLink>
              </li>
              <li>
                <NavLink onClick={handleShowNavbar} to="/about">
                  About
                </NavLink>
              </li>
              <li>
                <NavLink onClick={handleShowNavbar} to="/portfolio">
                  My work
                </NavLink>
              </li>
              <li>
                <NavLink onClick={handleShowNavbar} to="/contact">
                  Contact
                </NavLink>
              </li>
              <li>
                <span
                  className="material-symbols-outlined"
                  onClick={() => {
                    handleThemeChange();
                    handleShowNavbar();
                  }}
                >
                  {isDarkMode ? "light_mode" : "dark_mode"}
                </span>
              </li>
            </ul>
          </div>
        </div>
        <div
          className={`nav-overlay  ${showNavbar && "active"}`}
          onClick={handleShowNavbar}
        ></div>
      </nav>
      {notification && (
        <Notify
          message={notification.message}
          type={notification.type}
          delay={2999}
          onClose={() => setNotification(null)}
        />
      )}
    </>
  );
};

export default Navbar;
