import React, { useEffect, useState } from "react";
import Notify from "../Components/Notify";

const Contact = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });
  const [notification, setNotification] = useState(null);
  const showNotification = (message, type) => {
    setNotification({ message, type });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const url = "https://developedbybarn.com/process-contact-form";
    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        showNotification(
          "Thank you for contacting Barnabas Mwenda. Your message was sent successfully!",
          "success"
        );

        setFormData({
          name: "",
          email: "",
          message: "",
        });
      } else {
        showNotification(
          "Oops! Something went wrong. Please try again.",
          "error"
        );
      }
    } catch (error) {
      showNotification("Oops Something went wrong!", "error");
    }
  };

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "instant" });
  }, []);
  return (
    <>
      <div className="hero-container">
        <div className="flex-container contact-container">
          <div className="row contact-container-left">
            <h1 className="contact-header gradient-text slideFromTop">
              Get in touch
            </h1>
            <p className="slideFromTop">
              Your needs and questions are important, and I'm here to help in
              any way I can. How may I assist you today?
            </p>
            <div className="separator mt-2 pr-2 slideFromRight"></div>
            <h2 className="mt-2 slideFromBottom">Reach out directly</h2>
            <p className="slideFromBottom">
              I'm here to connect, problem-solve, and bring your innovative
              solutions to life.
            </p>
            <div className="d-flex-center mt-2 slideFromBottom">
              <span className="material-symbols-outlined">mail</span>
              <a
                className="ml-0 primary-alt underlined"
                href="mailto:info@storyprofilm.com"
              >
                hello@developedbybarn.com
              </a>
            </div>
          </div>
          <div className="row">
            <div className="form-container slideFromBottom">
              <form onSubmit={handleSubmit}>
                <div className="form-group">
                  <label htmlFor="name">Name</label>
                  <input
                    type="text"
                    id="name"
                    name="name"
                    aria-label="Name"
                    required
                    onChange={(e) => {
                      setFormData({
                        ...formData,
                        name: e.target.value,
                      });
                    }}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="email">Email</label>
                  <input
                    type="email"
                    id="email"
                    name="email"
                    aria-label="Email"
                    required
                    onChange={(e) => {
                      setFormData({
                        ...formData,
                        email: e.target.value,
                      });
                    }}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="message">Message</label>
                  <textarea
                    id="message"
                    name="message"
                    aria-label="Message"
                    required
                    onChange={(e) => {
                      setFormData({
                        ...formData,
                        message: e.target.value,
                      });
                    }}
                  ></textarea>
                </div>
                <div className="d-flex flex-end">
                  <button className="main-button" type="submit">
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {notification && (
        <Notify
          message={notification.message}
          type={notification.type}
          delay={999999}
          onClose={() => setNotification(null)}
        />
      )}
    </>
  );
};

export default Contact;
