import React from "react";

const Footer = () => {
  return (
    <div className="container footer-section">
      <div className="footer">
        <div className="col">
          <p>Barnabas Mwenda</p>
        </div>
        <div className="col">
          <p>Full-Stack Software Engineer | UX Engineer</p>
        </div>
      </div>
      <div className="follow">
        <div className="icon">
          <a href="https://www.linkedin.com/in/barnabas-mwenda-322320281/">
            <i className="fab fa-linkedin"></i>
          </a>
          <a href="https://github.com/barnabasmwenda">
            <i className="fab fa-github"></i>
          </a>
        </div>
      </div>
      <div className="copyright">
        <p>
          developedbybarn🚀 | &copy;
          {new Date().getFullYear()}
        </p>
      </div>
    </div>
  );
};

export default Footer;
