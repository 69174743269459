import React, { useEffect, useState } from "react";
import blogIntroEnglish from "../Assets/Images/blog-intro-english.png";
import blogIntroEnglishMenuOpen from "../Assets/Images/blog-intro-english-blog-menu-open.png";
import blogIntroSwahili from "../Assets/Images/blog-intro-swahili.png";
import blogIntroSwahiliMenuOpen from "../Assets/Images/blog-intro-swahili-menu-open.png";
import dashboardEnglishDark from "../Assets/Images/dashboard-english-dark.png";
import dashboardEnglishDarkCollapsed from "../Assets/Images/dashboard-english-dark-collapsed.png";
import dashboardEnglishWhite from "../Assets/Images/dashboard-english-white.png";
import dashboardEnglishWhiteCollapsed from "../Assets/Images/dashboard-english-white-collapsed.png";
import whiteBlogIntroEnglish from "../Assets/Images/white-blog-intro-english.png";
import whiteBlogIntroEnglishMenuOpen from "../Assets/Images/white-blog-intro-english-blog-menu-open.png";
import whiteBlogIntroSwahili from "../Assets/Images/white-blog-intro-swahili.png";
import whiteBlogIntroSwahiliMenuOpen from "../Assets/Images/white-blog-intro-swahili-menu-open.png";
import prodbybarnV1 from "../Assets/Images/prodbybarn-v1.png";
import prodbybarnV1_2 from "../Assets/Images/prodbybarn-v1-2.png";

const ProjectProdbybarn = () => {
  const [quickNavLinks, setQuickNavLinks] = useState([]);

  useEffect(() => {
    const h2Elements = document.querySelectorAll(".project-story-right h2");
    const links = Array.from(h2Elements).map((h2) => ({
      link: h2.textContent,
      offset: h2.offsetTop,
    }));

    setQuickNavLinks(links);
    window.scrollTo({ top: 0, behavior: "instant" });
  }, []);

  const handleLinkClick = (offset) => {
    window.scrollTo({
      top: offset - 80,
      behavior: "smooth",
    });
  };

  const renderTableOfContents = () => {
    return quickNavLinks.map((linkObj, index) => (
      <p key={index} onClick={() => handleLinkClick(linkObj.offset)}>
        {linkObj.link}
      </p>
    ));
  };

  return (
    <>
      <div className="hero-container d-flex-center">
        <div className="d-flex flex-column flex-end">
          <h1 className="slideFromTop">ProdbyBarn Web App</h1>
          <h1 className="gradient-text slideFromTop">
            A Music Production Company
          </h1>
          <p className="slideFromRight long-text">
            Empowering a diverse community of aspiring artists, producers,
            content creators, and music enthusiasts is the core mission of our
            company, achieved through the provision of complimentary music
            production courses.
          </p>
          <div className="cta-buttons">
            <a href="https://www.prodbybarn.com" className="main-button">
              See Live Site
            </a>
          </div>
        </div>
      </div>
      <div className="project-story container">
        <div className="project-story-left">
          <div className="table-of-contents-wrapper padding-cards light-background">
            <h3 className="hidden">In this page</h3>
            <div className="separator mt-0"></div>
            {renderTableOfContents()}
          </div>
        </div>
        <div className="project-story-right project-info-text">
          <h2>ProdbyBarn e-commerce web app</h2>
          <div className="image-container">
            <img
              src={blogIntroEnglish}
              alt="Project ProdbyBarn Blog Intro English"
            />
          </div>
          <p className="mt-2">
            ProdbyBarn is a company committed to fostering creativity by
            empowering a diverse community of aspiring artists. Their mission
            unfolds through the provision of complimentary music production
            courses, transcending traditional education to offer transformative
            experiences that unlock the unique creative potential within each
            participant.
          </p>
          <p className="mt-2">
            Additionally, this innovative web app acts as a comprehensive
            e-commerce platform, featuring a curated selection of digital music
            production sample packs for sale, music beats/instrumentals and
            other digital music production tools.
          </p>
          <p className="mt-2">
            Beyond education and sample packs, music beats and digital music
            creation tools, the web app functions as a marketplace for music
            services, offering recording, mixing, mastering, custom beats
            production, and top-tier audio Engineering services.
          </p>
          <p className="mt-2">
            This multifaceted approach ensures that aspiring talents not only
            gain proficiency in music production and audio Engineering but also
            access essential resources and services. ProdbyBarn was started in
            2018 but it was not until 2022 when I started the development of the
            e-commerce web application.
          </p>
          <div className="testimonial-highlight">
            <p>
              "Personally, I find the green adds a refreshing touch, evoking a
              sense of nature, growth and hope reflecting the brand's youthful
              spirit." - By ProdbyBarn, CEO ProdbyBarn Music
            </p>
          </div>
          <h2 className="mt-6">The goal</h2>
          <div className="image-container">
            <img
              src={blogIntroEnglishMenuOpen}
              alt="Project ProdbyBarn Blog Intro English menu open"
            />
          </div>
          <p className="mt-2">
            The overarching goal of this innovative web app is to democratize
            and demystify the realm of music creation and audio Engineering by
            providing a multifaceted platform for aspiring artists.
          </p>
          <p className="mt-2">
            The app aims to empower individuals from diverse backgrounds through
            free complimentary music production courses, fostering a supportive
            community and unlocking unique creative potential.
          </p>
          <p className="mt-2">
            The wealth of music production and audio Engineering learning
            materials is accessible in both English and Swahili languages.
            Furthermore, the entire system is designed to be navigated
            seamlessly in either language — English or Swahili.
          </p>
          <p className="mt-2">
            Additionally, the app serves as an e-commerce platform, offering
            digital music production sample packs and tools, music
            beats/instrumentals, and facilitating access to essential services
            such as recording, mixing, mastering, custom beats production, and
            audio Engineering.
          </p>
          <p className="mt-2">
            Ultimately, the goal is to create an inclusive space where artists,
            music producers, content creators and music enthusiasts can
            flourish, learn, and contribute to the ever-evolving tapestry of
            creative expression in the music industry.
          </p>
          <p className="mt-2">
            My primary focus for the web app has consistently been to maintain
            its usability, reliability, and functionality. Emphasizing an
            aesthetically minimal, simple, and clean design, the app is devoid
            of advertisements and gimmicks.
          </p>
          <p className="mt-2">
            Instead, it prioritizes delivering a steady and intuitive user
            experience, ensuring our users derive value, remain well-informed,
            satisfied, and engaged throughout their interaction with the
            platform.
          </p>
          <h2 className="mt-6">My role</h2>
          <div className="image-container">
            <img
              src={blogIntroSwahili}
              alt="Project ProdbyBarn blog intro swahili"
            />
          </div>
          <p className="mt-2">
            Having been the Software Engineer integral to the inception of the
            Web application, my role is an ongoing narrative that evolves with
            each new feature and update. From the initial development stages to
            the continuous enhancement of functionalities, I've been
            orchestrating the entire process.
          </p>
          <p className="mt-2">
            I initiated the creation of the Backend infrastructure and database
            management, ensuring the seamless flow of data. This foundational
            work continues to evolve with each subsequent feature, as I
            implement new functionalities and optimize existing ones to meet the
            ever-changing needs of our users.
          </p>
          <p className="mt-2">
            Simultaneously, my role as the UX Engineer has been an ongoing
            journey of crafting and refining the visual elements and user
            interactions for the best user experience.
          </p>
          <p className="mt-2">
            With every update, I strive to elevate the aesthetics and usability
            of the interface, aiming for a harmonious balance between form and
            function. This iterative design process ensures that the application
            not only meets but consistently exceeds user expectations.
          </p>
          <p className="mt-2">
            As the narrative of the web application development unfolds, my dual
            roles persistently drive the creation of a cohesive and innovative
            user experience. The story is one of continuous evolution, where
            each new feature and update represents a chapter in the ongoing
            journey of crafting a robust, visually compelling, and user-centric
            product.
          </p>
          <h2 className="mt-6">ProdbyBarn v1.0</h2>
          <div className="image-container">
            <img src={prodbybarnV1} alt="Project ProdbyBarnv1" />
          </div>
          <div className="image-container">
            <img src={prodbybarnV1_2} alt="Project ProdbyBarnv1" />
          </div>
          <p className="mt-2">
            Version 1 of my web app, marked the initial step in creating a
            platform dedicated to empowering aspiring artists, music producers,
            content creators and music enthusiasts.
          </p>
          <p className="mt-2">
            Designed with a deliberate focus on usability, reliability, and
            functionality, the first release offered users a clean and minimal
            aesthetic. While strong in its foundation, user feedback served as a
            catalyst for continuous improvement.
          </p>
          <h2 className="mt-6">ProdbyBarn v2.0</h2>
          <div className="image-container">
            <img src={blogIntroSwahiliMenuOpen} alt="Project ProdbyBarnv2" />
          </div>
          <div className="image-container">
            <img
              src={whiteBlogIntroEnglishMenuOpen}
              alt="Project ProdbyBarn white mode menu open"
            />
          </div>
          <p className="mt-2">
            I then released Version 2 of my web app, representing a significant
            leap forward in our commitment to evolving and enhancing the user
            experience.
          </p>
          <p className="mt-2">
            Drawing from valuable user insights, this version introduces a range
            of new features aimed at elevating the utility and engagement levels
            for my community. The user interface (UI) has undergone a meticulous
            redesign, embracing a sleek and modern look to enhance visual appeal
            and streamline navigation.
          </p>
          <p className="mt-2">
            Moreover, Version 2 places a heightened emphasis on refining the
            user experience (UX). Through careful adjustments and enhancements,
            I've created a more intuitive and seamless environment for users,
            ensuring that their interactions with my web app are both satisfying
            and enriching.
          </p>
          <p className="mt-2">
            With the launch of Version 2, I reaffirm my dedication to providing
            a dynamic and evolving space for aspiring artists, music producers,
            content creators and music enthusiasts where innovation and user
            satisfaction remain at the forefront of my mission.
          </p>
          <p className="mt-2">
            In Version 2 of my web app, I've dedicated efforts not only to
            enhancing the user experience with new features and an improved UI
            but also to substantial advancements in functionality, performance,
            and security.
          </p>
          <p className="mt-2">
            Within the realm of functionality, I've developed an extensive array
            of free educational materials for music production and audio
            Engineering. These resources are not only accessible in English but
            also in Swahili, reflecting my commitment to inclusivity. Users can
            seamlessly switch between English and Swahili languages throughout
            the entire system, offering a flexible and user-friendly experience.
          </p>
          <p className="mt-2">
            In addition to the multilingual support, the platform boasts a
            user-friendly interface and the learning materials cover a wide
            range of topics in music production and audio Engineering, ensuring
            a thorough and accessible learning experience for users.
            Additionally, interactive features and engaging content contribute
            to a dynamic and effective educational environment.
          </p>
          <p className="mt-2">
            Performance optimizations stands out as a key achievement in this
            release. These enhancements ensure that users can navigate the
            platform seamlessly, contributing to a more efficient, productive
            and enjoyable experience.
          </p>
          <p className="mt-2">
            Alongside enhancing the user experience with new features and an
            improved UI, this version now boasts comprehensive music production
            and audio Engineering blogs, providing users with valuable insights
            and knowledge in the realm of music production and audio
            Engineering.
          </p>
          <p className="mt-2">
            These blogs cover a spectrum of topics, from advanced techniques and
            industry trends to creative inspiration, offering a wealth of
            information to aspiring artists, music producers, content creators
            and music enthusiasts.
          </p>
          <p className="mt-2">
            Whether users are seeking guidance on creating instrumentals/music
            beats, mixing and mastering their tracks, exploring innovative
            production methods, or staying updated on the latest industry
            developments, the addition of these blogs enhances the educational
            aspect of my web app.
          </p>
          <p className="mt-2">
            Furthermore, Version 2 introduces a collection of additional music
            creation digital sample packs and tools. These tools are designed to
            complement the existing features, offering users more flexibility
            and creative possibilities within the app.
          </p>
          <p className="mt-2">
            By integrating comprehensive blogs and additional digital sample
            packs and tools, Version 2 of my web app not only serves as a
            creative workspace but also as a knowledge hub and resource center
            for aspiring artists, music producers, content creators and music
            enthusiasts.
          </p>
          <p className="mt-2">
            This evolution reflects my commitment to providing a holistic
            platform that nurtures creativity and supports the educational
            journey of users.
          </p>
          <h2 className="mt-6">User research and feedback analysis</h2>
          <div className="image-container">
            <img
              src={whiteBlogIntroSwahiliMenuOpen}
              alt="Project ProdbyBarn blog intro swahili menu open"
            />
          </div>
          <p>
            My engagement with user research and feedback analysis played a
            pivotal role in shaping the evolution of the web application.
          </p>
          <p className="mt-2">
            Actively seeking insights from our user community during the
            project's development phase, I meticulously delved into user
            behaviors and preferences.
          </p>
          <p>
            This ongoing process ensured that every facet of the web application
            resonated authentically with our audience. The routine analysis of
            user feedback served as an invaluable compass, guiding iterative
            improvements and solidifying a user-centric approach that persisted
            throughout the entire project lifecycle.
          </p>
          <h2 className="mt-6">Continuous learning and adaptation</h2>
          <div className="image-container">
            <img
              src={whiteBlogIntroSwahili}
              alt="Project ProdbyBarn blog intro swahili"
            />
          </div>
          <p className="mt-2">
            As the sole initiator and Engineer, I embraced a commitment to
            continuous learning and adaptation. In the dynamic landscape of
            technology and design, this proactive approach was foundational to
            the project's success.
          </p>
          <p className="mt-2">
            Regularly incorporating new tools, frameworks, and methodologies
            ensured that the web application maintained a position at the
            forefront of innovation, providing users with the latest and most
            efficient features.
          </p>
          <p className="mt-2">
            This dedication to staying abreast of industry trends wasn't merely
            a practice but a mindset deeply ingrained in the DNA of the project,
            fostering an environment of perpetual growth and advancement.
          </p>
          <h2 className="mt-6">Quality assurance and testing</h2>
          <div className="image-container">
            <img
              src={dashboardEnglishDark}
              alt="Project ProdbyBarn dashboard dark mode"
            />
          </div>
          <p className="mt-2">
            Ensuring the reliability and robustness of the web application was a
            responsibility I took seriously. Quality assurance and testing were
            integral aspects of my role.
          </p>
          <p className="mt-2">
            I meticulously conducted thorough testing procedures to identify and
            rectify any issues before they reached our users ensuring that the
            system was fully functional and a reliable platform.
          </p>
          <p className="mt-2">
            This commitment to quality was not just about meeting standards; it
            was about exceeding expectations. Every line of code and every
            design element underwent thorough scrutiny to guarantee a seamless
            and secure user experience.
          </p>
          <h2 className="mt-6">Project management</h2>
          <div className="image-container">
            <img
              src={dashboardEnglishDarkCollapsed}
              alt="Project ProdbyBarn dashboard dark mode collapsed menu"
            />
          </div>
          <p className="mt-2">
            Project management was a multifaceted aspect of my role. From task
            prioritization to timeline adherence, I orchestrated every facet of
            the development and design processes.
          </p>
          <p className="mt-2">
            I ensured that the project progressed smoothly, meeting project
            milestones, and achieving successful outcomes.
          </p>
          <p className="mt-2">
            It was not just about coding and design; it was about steering the
            entire project towards success, overcoming challenges, and
            delivering a product that aligned with the vision.
          </p>
          <h2 className="mt-6">Agile approach</h2>
          <div className="image-container">
            <img
              src={dashboardEnglishWhite}
              alt="Project ProdbyBarn dashboard english white"
            />
          </div>
          <p className="mt-2">
            I've harnessed the power of Agile development to perfection. This
            methodical approach enabled me to iteratively refine the user
            interface, seamlessly presenting extensive text in both e-commerce
            and course contexts.
          </p>
          <h2 className="mt-6">Challenges faced</h2>
          <div className="image-container">
            <img
              src={dashboardEnglishWhiteCollapsed}
              alt="Project ProdbyBarn dashboard light mode collapsed menu"
            />
          </div>
          <p className="mt-2">
            Embarking on the journey as a solo Software Engineer presented its
            unique set of challenges.
          </p>
          <p className="mt-2">
            Balancing the intricacies of both Full-Stack development and design,
            managing diverse tasks, and staying current with technological
            advancements demanded a meticulous and adaptive approach.
          </p>
          <p className="mt-2">
            Yet, these challenges became opportunities for growth. Each hurdle
            provided a chance to refine skills, enhance processes, and fortify
            the resilience needed to drive the project forward.
          </p>
          <p className="mt-2">
            In overcoming challenges, the project not only thrived but evolved
            into a testament to determination and expertise in the ever-evolving
            realm of web development.
          </p>
          <h2 className="mt-6">What's next</h2>
          <div className="image-container">
            <img
              src={whiteBlogIntroEnglish}
              alt="Project ProdbyBarn dashboard light mode collapsed menu"
            />
          </div>
          <p className="mt-2">
            I'm currently working on refining the core user experience, this
            involves actively listening to and incorporating valuable customer
            feedback, introducing innovative features, and expanding the
            accessibility of the product across a diverse range of platforms.
          </p>
        </div>
      </div>
    </>
  );
};

export default ProjectProdbybarn;
