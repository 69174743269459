import React, { useState, useEffect } from "react";

import slider_desktop1 from "../Assets/Images/storypro-about-us.png";
import slider_desktop2 from "../Assets/Images/storypro-video-productions.png";
import slider_desktop3 from "../Assets/Images/storypro-gallery.png";
import slider_desktop4 from "../Assets/Images/storypro-testimonial.png";
import slider_desktop5 from "../Assets/Images/storypro-contact.png";
import slider_desktop6 from "../Assets/Images/storypro-home.png";
import slider_desktop7 from "../Assets/Images/storypro-home-partners.png";
import slider_desktop8 from "../Assets/Images/storypro-home-final.png";
import slider_desktop9 from "../Assets/Images/storypro-testimonial-section.png";
import slider_desktop10 from "../Assets/Images/storypro-testimonial-section-2.png";
import slider_desktop11 from "../Assets/Images/storypro-modal.png";
import slider_desktop12 from "../Assets/Images/storypro-modal-2.png";

import slider_mobile1 from "../Assets/Images/story-pro-mobile.png";
import slider_mobile2 from "../Assets/Images/story-pro-mobile-video.png";
import slider_mobile3 from "../Assets/Images/story-pro-gallery.png";
import slider_mobile4 from "../Assets/Images/story-pro-mobile-testimonials.png";
import slider_mobile5 from "../Assets/Images/story-pro-mobile-contact.png";
import slider_mobile6 from "../Assets/Images/story-pro-mobile-nav-open.png";

import sliderDesktop1 from "../Assets/Images/blog-intro-english.png";
import sliderDesktop2 from "../Assets/Images/blog-intro-english-blog-menu-open.png";
import sliderDesktop3 from "../Assets/Images/blog-intro-swahili.png";
import sliderDesktop4 from "../Assets/Images/blog-intro-swahili-menu-open.png";
import sliderDesktop5 from "../Assets/Images/dashboard-english-dark.png";
import sliderDesktop6 from "../Assets/Images/dashboard-english-dark-collapsed.png";
import sliderDesktop7 from "../Assets/Images/dashboard-english-white.png";
import sliderDesktop8 from "../Assets/Images/dashboard-english-white-collapsed.png";
import sliderDesktop9 from "../Assets/Images/white-blog-intro-english.png";
import sliderDesktop10 from "../Assets/Images/white-blog-intro-english-blog-menu-open.png";
import sliderDesktop11 from "../Assets/Images/white-blog-intro-swahili.png";
import sliderDesktop12 from "../Assets/Images/white-blog-intro-swahili-menu-open.png";

import sliderMobile1 from "../Assets/Images/blog-intro-english-mobile.png";
import sliderMobile2 from "../Assets/Images/blog-intro-english-mobile-menu-open.png";
import sliderMobile3 from "../Assets/Images/blog-intro-swahili-mobile.png";
import sliderMobile4 from "../Assets/Images/blog-intro-swahili-mobile-menu-open.png";
import sliderMobile5 from "../Assets/Images/dashboard-english-dark-mobile.png";
import sliderMobile6 from "../Assets/Images/dashboard-english-white-mobile.png";
import sliderMobile7 from "../Assets/Images/dashboard-swahili-dark-mobile.png";
import sliderMobile8 from "../Assets/Images/dashboard-swahili-white-mobile.png";
import sliderMobile9 from "../Assets/Images/white-blog-intro-english-mobile.png";
import sliderMobile10 from "../Assets/Images/white-blog-intro-english-mobile-menu-open.png";

const Slider = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const desktopImages = [
    slider_desktop1,
    slider_desktop2,
    slider_desktop3,
    slider_desktop4,
    slider_desktop5,
    slider_desktop6,
    slider_desktop7,
    slider_desktop8,
    slider_desktop9,
    slider_desktop10,
    slider_desktop11,
    slider_desktop12,
    sliderDesktop1,
    sliderDesktop2,
    sliderDesktop3,
    sliderDesktop4,
    sliderDesktop5,
    sliderDesktop6,
    sliderDesktop7,
    sliderDesktop8,
    sliderDesktop9,
    sliderDesktop10,
    sliderDesktop11,
    sliderDesktop12,
  ];
  const mobileImages = [
    slider_mobile1,
    slider_mobile2,
    slider_mobile3,
    slider_mobile4,
    slider_mobile5,
    slider_mobile6,
    sliderMobile1,
    sliderMobile2,
    sliderMobile3,
    sliderMobile4,
    sliderMobile5,
    sliderMobile6,
    sliderMobile7,
    sliderMobile8,
    sliderMobile9,
    sliderMobile10,
  ];

  const handleResize = () => {
    const screenWidth = window.innerWidth;

    if (screenWidth >= 768) {
      setCurrentSlide((prevSlide) =>
        prevSlide >= desktopImages.length - 1 ? 0 : prevSlide + 1
      );
    } else {
      setCurrentSlide((prevSlide) =>
        prevSlide >= mobileImages.length - 1 ? 0 : prevSlide + 1
      );
    }
  };

  useEffect(() => {
    handleResize();

    const interval = setInterval(() => {
      setCurrentSlide(
        (prevSlide) =>
          (prevSlide + 1) %
          (window.innerWidth >= 768
            ? desktopImages.length
            : mobileImages.length)
      );
    }, 6999);

    window.addEventListener("resize", handleResize);

    return () => {
      clearInterval(interval);
      window.removeEventListener("resize", handleResize);
    };
    // eslint-disable-next-line
  }, [desktopImages.length, mobileImages.length]);

  return (
    <div className="slider-container">
      <div
        className="slides"
        style={{ transform: `translateX(-${currentSlide * 100}%)` }}
      >
        {(window.innerWidth >= 768 ? desktopImages : mobileImages).map(
          (imageUrl, index) => (
            <div key={index} className="slide">
              <img src={imageUrl} alt={`Slide ${index + 1}`} />
            </div>
          )
        )}
      </div>
    </div>
  );
};

export default Slider;
