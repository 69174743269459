import React, { useEffect } from "react";
import { NavLink } from "react-router-dom";
import prodbybarnLogo from "../Assets/Images/PRODBYBARN-LOGO.png";
import storyprofilmBlack from "../Assets/Images/story pro fil logo.png";
import Slider from "../Components/Slider";

const Home = () => {
  const testimonialsData = [
    {
      id: 1,
      name: "Nigel Otieno",
      position: "CEO, Storyprofilm Network",
      companyProfile: "Video Production Company",
      testimonial:
        "Love the design! The theme colors pops & reflects my brand. The site loads instantly and I'm thrilled with the SEO performance!.",
      companyLogo: (
        <>
          <img
            className="prodbybarn-logo"
            src={storyprofilmBlack}
            alt="Storyprofilm"
          />
        </>
      ),
      logoLink: "https://www.storyprofilm.com",
    },
    {
      id: 2,
      name: "ProdbyBarn",
      position: "CEO, ProdbyBarn Music",
      companyProfile: "Music Production Company",
      testimonial:
        "Personally, I find the green adds a refreshing touch, evoking a sense of nature, growth and hope reflecting the brand's youthful spirit.",
      companyLogo: (
        <>
          <img src={prodbybarnLogo} alt="ProdbyBarn" />
        </>
      ),
      logoLink: "https://www.prodbybarn.com",
    },
  ];

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "instant" });
  }, []);

  return (
    <>
      <div className="hero-container d-flex-center">
        <div id="hero-content">
          <h1 className="slideFromTop">Hi, I am</h1>
          <div className="slideFromTop">
            <h1 className="gradient-text">Barnabas Mwenda Munanu</h1>
          </div>
          <p className="slideFromRight long-text">
            I am a Software Engineer specializing in crafting stunning and
            unforgettable user-centric Web Apps.
          </p>
          <div className="mt-2">
            <NavLink className="main-button" to="/contact">
              Get in Touch
            </NavLink>
          </div>
          <div className="arrow">
            <span className="material-symbols-outlined">south</span>
          </div>
        </div>
      </div>
      <div className="container">
        <h1 className="text-center">My Recent Projects</h1>
        <div className="mt-0">
          <Slider />
        </div>
        <div className="d-flex-center">
          <a href="https://www.storyprofilm.com" className="big-link">
            Visit storyprofilm.com
          </a>
          <a href="https://www.prodbybarn.com" className="big-link">
            Visit prodbybarn.com
          </a>
        </div>
      </div>
      <div className="container d-flex flex-container services-section">
        <div className="row">
          <div className="benefits-row">
            <div className="benefits-column">
              <div className="responsive-card d-flex-center flex-column">
                <span className="material-symbols-outlined">speed</span>
                <p className="responsive-card-text">
                  I am good at building high-performance web applications
                </p>
              </div>
              <div className="responsive-card d-flex-center flex-column">
                <span className="material-symbols-outlined">assignment</span>
                <p className="responsive-card-text">
                  I thrive on delivering solutions that meet client needs
                </p>
              </div>
            </div>
            <div className="benefits-column">
              <div className="responsive-card d-flex-center flex-column">
                <span className="material-symbols-outlined">touch_app</span>
                <p className="responsive-card-text">
                  I'm proficient in crafting user-friendly & intuitive
                  interfaces
                </p>
              </div>
              <div className="responsive-card d-flex-center flex-column">
                <span className="material-symbols-outlined">lightbulb</span>
                <p className="responsive-card-text">
                  I'm creative and I posess strong problem-solving and
                  analytical skills
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="row benefits-text-section" id="benefits">
          <h1>Get Speed, Solutions, and Skills</h1>
          <p>
            As creators, we architect systems that serve the needs of users,
            recognizing that our creations are ultimately for their benefit.
          </p>
          <div className="cta-buttons">
            <NavLink className="secondary-button" to="/portfolio">
              See my work
            </NavLink>
          </div>
        </div>
      </div>
      <div className="testimonials-container">
        <div className="mt-6 container">
          <h1 className="text-center">Testimonials</h1>
          <p className="text-center">
            Real experiences real impact: My clients have nice things to say and
            speak for me.
          </p>
        </div>
        <div className="testimonials container mt-2">
          <div className="d-flex-justified testimonial-data-wrapper">
            {testimonialsData.map((testimonial) => (
              <div key={testimonial.id} className="testimonial mt-0">
                <div className="testimonial-info">
                  <a href={testimonial.logoLink}>
                    <div className="testimonial-logo">
                      {testimonial.companyLogo}
                    </div>
                  </a>

                  <p className="testimonial-text">
                    "{testimonial.testimonial}"
                  </p>
                  <div className="client-info">
                    <p className="client-name">{testimonial.name}</p>
                    <p className="client-position">{testimonial.position}</p>
                    <p className="client-profile">
                      {testimonial.companyProfile}
                    </p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="d-flex-center d-flex-justified container flex-column lets-work">
        <h1 className="text-center">Lets work together</h1>
        <p className="text-center">
          Do you have a project in mind? Let's collaborate to create fast,
          high-performance UI/UX web and Software solutions that engage users
          and deliver results.
        </p>
        <div className="d-flex-justified">
          <NavLink className="secondary-button" to="/contact">
            Get in Touch
          </NavLink>
        </div>
      </div>
    </>
  );
};

export default Home;
