import React, { useState, useEffect } from "react";

const Notify = ({ message, type, delay, onClose }) => {
  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setIsVisible(false);
      onClose();
    }, delay);

    return () => clearTimeout(timeout);
  }, [delay, onClose]);

  return isVisible ? (
    <div className={`notification ${type}`}>
      {type === "success" ? (
        <span className="material-symbols-outlined">info</span>
      ) : (
        <span className="material-symbols-outlined">error</span>
      )}
      <div className="message">{message}</div>
      <button className="close-btn" onClick={() => setIsVisible(false)}>
        &times;
      </button>
    </div>
  ) : null;
};

export default Notify;
