import React from "react";
import { NavLink } from "react-router-dom";

const GridCards = ({ data }) => {
  return (
    <div className="grid-container">
      {data.map((card) => (
        <NavLink to={card.link} key={card.id}>
          <div className="grid-card">
            <div className="card-content">
              <div>
                <span className="material-symbols-outlined">code</span>
              </div>
              <h3 className="card-title mt-0">{card.title}</h3>
              <div className="separator mt-0"></div>
              <p className="card-description mt-0">{card.description}</p>
            </div>
          </div>
        </NavLink>
      ))}
    </div>
  );
};

export default GridCards;
