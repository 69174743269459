import React, { useEffect, useState } from "react";
import homePage from "../Assets/Images/storypro-home.png";
import partners from "../Assets/Images/storypro-home-partners.png";
import homeFinal from "../Assets/Images/storypro-home-final.png";
import testimoanialSection from "../Assets/Images/storypro-testimonial-section.png";
import testimoanialSection2 from "../Assets/Images/storypro-testimonial-section-2.png";
import modal1 from "../Assets/Images/storypro-modal.png";
import modal2 from "../Assets/Images/storypro-modal-2.png";
import aboutUs from "../Assets/Images/storypro-about-us.png";
import videoProductions from "../Assets/Images/storypro-video-productions.png";
import gallery from "../Assets/Images/storypro-gallery.png";
import testimoanial from "../Assets/Images/storypro-testimonial.png";
import contact from "../Assets/Images/storypro-contact.png";

const ProjectStoryprofilm = () => {
  const [quickNavLinks, setQuickNavLinks] = useState([]);

  useEffect(() => {
    const h2Elements = document.querySelectorAll(".project-story-right h2");
    const links = Array.from(h2Elements).map((h2) => ({
      link: h2.textContent,
      offset: h2.offsetTop,
    }));

    setQuickNavLinks(links);
    window.scrollTo({ top: 0, behavior: "instant" });
  }, []);

  const handleLinkClick = (offset) => {
    window.scrollTo({
      top: offset - 80,
      behavior: "smooth",
    });
  };

  const renderTableOfContents = () => {
    return quickNavLinks.map((linkObj, index) => (
      <p key={index} onClick={() => handleLinkClick(linkObj.offset)}>
        {linkObj.link}
      </p>
    ));
  };

  return (
    <>
      <div className="hero-container d-flex-center">
        <div className="d-flex flex-column flex-end">
          <h1 className="slideFromTop">Project Storyprofilm Website</h1>
          <h1 className="gradient-text slideFromTop">
            A Video Production Company
          </h1>
          <p className="slideFromRight long-text">
            A dedicated video production company specializing in crafting
            engaging visual narratives and impact stories for brands and
            organizations, employing innovative storytelling and
            state-of-the-art video production techniques.
          </p>
          <div className="cta-buttons">
            <a href="https://www.storyprofilm.com" className="main-button">
              See Live Site
            </a>
          </div>
        </div>
      </div>
      <div className="project-story container">
        <div className="project-story-left">
          <div className="table-of-contents-wrapper padding-cards light-background">
            <h3 className="hidden">In this page</h3>
            <div className="separator mt-0"></div>
            {renderTableOfContents()}
          </div>
        </div>
        <div className="project-story-right project-info-text">
          <h2>StoryPro Film website</h2>
          <div className="image-container">
            <img src={homePage} alt="Project Storyprofilm homepage" />
          </div>
          <p className="mt-2">
            StoryPro Film, a project I proudly developed, embodies a mission to
            craft captivating visual narratives. Through innovative storytelling
            and cutting-edge production, the website mirrors the company's
            dedication to empowering clients, leaving a lasting impact on
            audiences.
          </p>
          <p className="mt-2">
            It stands as a testament to their vision of becoming a premier
            destination for unparalleled video production expertise, setting
            industry standards.
          </p>
          <p className="mt-2">
            The website seamlessly aligns with StoryPro Film's goal of elevating
            impact narratives with visual excellence, offering a user experience
            that goes beyond conventional service - a digital partnership in
            bringing visions to life.
          </p>
          <div className="testimonial-highlight">
            <p>
              "Love the design! The theme colors pops & reflects my brand. The
              site loads instantly and I'm thrilled with the SEO performance!."
              - By Nigel Otieno, CEO StoryProfilm
            </p>
          </div>
          <h2 className="mt-6">The goal</h2>
          <div className="image-container">
            <img src={aboutUs} alt="Project Storyprofilm aboutUs" />
          </div>
          <p className="mt-2">
            The overarching project goal is to create a digital space that
            reflects StoryPro Film's commitment to impactful storytelling. By
            combining informative content, engaging testimonials, visually
            striking galleries, and accessible contact options, the website
            becomes a powerful tool for connecting with audiences, fostering
            collaborations, and leaving a lasting impression on visitors.
          </p>
          <p className="mt-2">
            The inclusion of testimonial videos adds a dynamic element, allowing
            potential clients to gain firsthand insights into the experiences of
            those who have collaborated with StoryPro Film. This personal touch
            aims to build trust and credibility, showcasing the positive impact
            the company has had on its clients.
          </p>
          <p className="mt-2">
            The gallery page serves as a visual showcase, highlighting the
            captivating work and visual narratives crafted by StoryPro Film.
            This not only allows visitors to experience the company's creative
            prowess but also serves as a testament to the dedication and skill
            invested in each project.
          </p>
          <p className="mt-2">
            Seamless access to contact information and a user-friendly contact
            form ensures that clients, collaborators, and prospective partners
            can easily get in touch with StoryPro Film. Whether it's inquiries
            about services, collaboration opportunities, or general information,
            the website provides a streamlined communication channel.
          </p>
          <h2 className="mt-6">Visual design</h2>
          <div className="image-container">
            <img src={homeFinal} alt="Project Storyprofilm homepage section" />
          </div>
          <p className="mt-2">
            The visual design of the StoryPro Film website was meticulously
            crafted to resonate with the essence of captivating storytelling.
          </p>
          <p className="mt-2">
            Drawing inspiration from the brand's mission, I curated a visual
            language that embraced modernity while paying homage to the timeless
            art of visual narratives. The color palette, prominently featuring
            StoryPro Film's signature hues, was intentionally chosen to create a
            memorable visual identity.
          </p>
          <p className="mt-2">
            The layout and structure of the website were designed with a keen
            eye for balance and harmony. Through a minimalist approach, the
            website exuded sophistication, allowing the content to take center
            stage.
          </p>
          <p className="mt-2">
            A clutter-free interface ensured that visitors could effortlessly
            navigate through the various sections, providing an unobtrusive
            canvas for the captivating stories told by StoryPro Film.
          </p>
          <p className="mt-2">
            Typography played a crucial role in enhancing the overall aesthetic.
            Thoughtful font choices were made to strike a balance between
            readability and personality.
          </p>
          <p className="mt-2">
            Every aspect of the visual design was a deliberate effort to create
            an immersive and memorable experience, aligning seamlessly with
            StoryPro Film's dedication to cutting-edge production and innovative
            storytelling.
          </p>
          <h2 className="mt-6">My role</h2>
          <div className="image-container">
            <img
              src={testimoanialSection}
              alt="Project Storyprofilm testimonial section"
            />
          </div>
          <p className="mt-2">
            My role played a pivotal part in shaping the visual and interactive
            aspects of the platform.
          </p>
          <p className="mt-2">
            I dedicated myself to crafting an appealing and intuitive interface
            that aligns with StoryPro Film's mission of captivating impact
            visual narratives ensuring a seamless and engaging user experience.
          </p>
          <p className="mt-2">
            Iterative design processes were employed to refine the visual
            elements, focusing on creating a user-centric experience that goes
            beyond conventional standards.
          </p>
          <p className="mt-2">
            The development aspects involved translating these designs into a
            fully functional website. Leveraging my expertise in Frontend
            technologies, I implemented responsive layouts, interactive
            features, and seamless navigation to enhance the overall user
            journey.
          </p>
          <p className="mt-2">
            My commitment to an aesthetically pleasing, minimalistic design was
            carried through every aspect of the website. Throughout the
            project's lifecycle, I maintained a user-first approach, ensuring
            that each visual and interactive element met and exceeded user
            expectations.
          </p>
          <p className="mt-2">
            I continually worked to deliver a website that not only reflects
            StoryPro Film's brand identity but also provides an enjoyable and
            immersive experience for its audiences.
          </p>
          <p className="mt-2">
            My role in the StoryPro Film website's development is an ongoing
            narrative of creativity, innovation, and user-centric design. Each
            line of code, each pixel, contributes to a compelling and visually
            striking digital platform that stands as a testament to the power of
            storytelling through design and technology.
          </p>
          <h2 className="mt-6">User experience</h2>
          <div className="image-container">
            <img
              src={testimoanialSection2}
              alt="Project Storyprofilm testimonial section2"
            />
          </div>
          <p className="mt-2">
            Beyond the aesthetics, the user experience was at the forefront of
            the website's development. The interface was designed with the user
            in mind, ensuring a journey that was not only visually pleasing but
            also intuitive and engaging. From the landing page to the deepest
            corners of the site, the user's interaction was considered at every
            step.
          </p>
          <p className="mt-2">
            Navigation was streamlined to provide a fluid experience. Load times
            were optimized to enhance the overall experience.
          </p>
          <p className="mt-2">
            Responsive design principles were implemented, guaranteeing a
            consistent and enjoyable experience across various devices, from
            desktops to smartphones.
          </p>
          <p className="mt-2">
            Interactive elements were thoughtfully integrated to captivate users
            and encourage exploration. Whether it was a captivating video
            background or subtle animations, these features were designed to
            enhance engagement without compromising the site's performance.
          </p>
          <h2 className="mt-6">User feedback</h2>
          <div className="image-container">
            <img src={contact} alt="Project Storyprofilm modal1" />
          </div>
          <p className="mt-2">
            The StoryPro Film website has been met with enthusiastic feedback
            from users, and this positive reception has been instrumental in
            shaping the ongoing evolution of the platform. Users have expressed
            appreciation for the website's appealing design, noting how it
            effectively captures the essence of StoryPro Film's commitment to
            innovative storytelling.
          </p>
          <p className="mt-2">
            SEO performance has been a key focus, and the positive impact has
            not gone unnoticed. The website's visibility and discoverability
            have been commended, with users expressing satisfaction in finding
            relevant content effortlessly.
          </p>
          <h2 className="mt-6">User research and feedback analysis</h2>
          <div className="image-container">
            <img src={modal1} alt="Project Storyprofilm modal1" />
          </div>
          <p className="mt-2">
            User research has been a foundational pillar in shaping the
            trajectory of the StoryPro Film website. Actively engaging with the
            user community during the development phase allowed for a deep
            understanding of their behaviors, preferences, and expectations.
            This iterative process ensured that every aspect of the website
            resonated authentically with the audience, creating an experience
            tailored to their needs.
          </p>
          <p className="mt-2">
            The routine analysis of user feedback played a pivotal role in the
            evolution of the web application. User insights were meticulously
            examined to identify patterns, preferences, and pain points.
          </p>
          <h2 className="mt-6">Continuous learning and adaptation</h2>
          <div className="image-container">
            <img src={modal2} alt="Project Storyprofilm modal1" />
          </div>
          <p className="mt-2">
            Embracing a commitment to continuous learning and adaptation has
            been fundamental to the success of the StoryPro Film website. In the
            dynamic landscape of technology and design, this proactive approach
            was not just a practice but a mindset deeply ingrained in the DNA of
            the project.
          </p>
          <p className="mt-2">
            Staying abreast of industry trends wasn't merely a task; it was a
            dedication to fostering an environment of perpetual growth and
            advancement.
          </p>
          <p className="mt-2">
            This mindset translated into a platform that provided users with the
            latest and most efficient features, ensuring that StoryPro Film
            consistently delivered cutting-edge solutions.
          </p>
          <h2 className="mt-6">Quality assurance and testing</h2>
          <div className="image-container">
            <img src={partners} alt="Project Storyprofilm parners" />
          </div>
          <p className="mt-2">
            Ensuring the reliability and robustness of the StoryPro Film website
            was a responsibility I approached with utmost seriousness. Quality
            assurance and testing were integral aspects of my role, serving as
            safeguards to deliver a seamless and secure user experience.
          </p>
          <h2 className="mt-6">Project management</h2>
          <div className="image-container">
            <img
              src={videoProductions}
              alt="Project Storyprofilm video productions"
            />
          </div>
          <p className="mt-2">
            Project management became a multifaceted aspect of my role. From
            task prioritization to timeline adherence, I orchestrated every
            facet of the development and design processes to ensure the project
            progressed smoothly and achieved successful outcomes.
          </p>
          <p className="mt-2">
            Collaborating with stakeholders, I ensured that the project remained
            aligned with the vision of StoryPro Film. The management extended
            beyond coding and design; it was about steering the entire project
            towards success, overcoming challenges, and delivering a product
            that not only met but exceeded expectations.
          </p>
          <p className="mt-2">
            The emphasis was on creating a platform that seamlessly integrated
            visual storytelling and cutting-edge production, meeting the goals
            set by StoryPro Film and providing an exceptional user experience.
          </p>
          <h2 className="mt-6">Agile approach</h2>
          <div className="image-container">
            <img src={gallery} alt="Project Storyprofilm gallery" />
          </div>
          <p className="mt-2">
            Agile principles allowed for adaptive planning and evolutionary
            development, ensuring that the website evolved in response to
            changing requirements and feedback. Regular interactions with
            stakeholders facilitated a dynamic feedback loop, enabling me to
            incorporate valuable insights and refine features based on their
            evolving needs and expectations.
          </p>
          <p className="mt-2">
            This iterative and collaborative approach not only ensured that the
            website met StoryPro Film's objectives but also provided
            stakeholders with a hands-on role in shaping the final product. The
          </p>
          <h2 className="mt-6">Challenges faced</h2>
          <p className="mt-2">
            Embarking on the development journey presented its unique set of
            challenges. Balancing the intricacies of both development and
            design, managing diverse tasks, and staying current with
            technological advancements demanded a meticulous and adaptive
            approach.
          </p>
          <p className="mt-2">
            These challenges, while demanding, became opportunities for growth.
            Each hurdle provided a chance to refine skills, enhance processes,
            and fortify the resilience needed to drive the StoryPro Film website
            forward.
          </p>
          <h2 className="mt-6">What's next</h2>
          <div className="image-container">
            <img src={testimoanial} alt="Project Storyprofilm testimonials" />
          </div>
          <p className="mt-2">
            As the ongoing journey of refining the StoryPro Film website
            continues, I remain dedicated to enhancing the core user experience.
            Actively listening to and incorporating valuable customer feedback
            remains a top priority, ensuring that the website evolves in direct
            response to the needs and preferences of its users.
          </p>
          <p className="mt-2">
            Innovation remains at the forefront, with plans to introduce
            exciting and cutting-edge features that will further elevate the
            storytelling capabilities of our clients. The goal is to expand the
            accessibility of the product across a diverse range of platforms,
            ensuring that users can engage seamlessly with StoryPro Film's
            services wherever they are.
          </p>
        </div>
      </div>
    </>
  );
};

export default ProjectStoryprofilm;
