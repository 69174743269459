import React, { useEffect } from "react";
import GridCards from "../Components/GridCards";
import Slider from "../Components/Slider";
import { NavLink } from "react-router-dom";

const Portfolio = () => {
  const cardsData = [
    {
      id: 1,
      title: "StoryProfilm Network Website Development",
      description:
        "A video production company whose mission is to craft captivating impact visual narratives for brands and organizations through innovative and impactful storytelling and cutting-edge production.",
      link: "/storyprofilm",
    },
    {
      id: 2,
      title: "ProbyBarn E-Commerce App Development",
      description:
        "A music production company dedicated to unlocking creative potential through offering free music production and audio Engineering educational resources and materials that empower aspiring artists.",
      link: "/prodbybarn",
    },
  ];

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "instant" });
  }, []);

  return (
    <>
      <div className="hero-container d-flex-center">
        <div id="hero-content" className="centered-text">
          <h1 className="slideFromTop">Building Seamless Experiences</h1>
          <h1 className="gradient-text slideFromTop">
            Crafting your Future with Code
          </h1>
          <p className="slideFromRight long-text">
            I strive to merge creativity with technology, pushing boundaries to
            deliver impactful user solutions.
          </p>
          <div className="mt-2">
            <NavLink className="main-button slideFromBottom" to="/contact">
              Get in Touch
            </NavLink>
          </div>
        </div>
      </div>
      <div className="container cards-wrapper">
        <h1 className="text-center">Featured Projects</h1>
        <div className="mt-0">
          <GridCards data={cardsData} />
        </div>
      </div>
      <div className="container">
        <div className="mt-0">
          <Slider />
        </div>
      </div>
    </>
  );
};

export default Portfolio;
